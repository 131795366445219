.bg-blue-pt{
  background-color: $main-color-1;
}


/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.f-normal{
  font-weight: 100 !important;
}

.color-white{
  color: white; 
}

// Custom CSS

.force-100-percentage{
  width: 100% !important;
  height: 300px
}

.fileUpload {
  position: relative;
  overflow: hidden;
  text-decoration: no-underline;

  & input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.ui-select-search{
  width:100% !important;
}


.search-input .form-group{
    width: 85%;
}
.search-input .form-group input {
  width: 100%;
}
  // autocomplete modal fix

  .pac-container {
    background-color: #FFF;
    z-index: 999999;
    position: fixed;
    display: inline-block;
    float: left;
  }

@media (max-width: 768px){
  .vertical-timeline-content::before{
    display: none;
  }
  .vertical-timeline-content{
    margin-left:0;
  }
  .fileUpload{
    margin: 10px 30px 9px 30px;
  }
  .search-input .form-group{
    width: 100%;
  }
}

.bg-transparent{
  background:transparent
}

.required{
  color:red;
  font-size: 0.8em;
}

/* adapted from http://maxwells.github.io/bootstrap-tags.html */
.tag {
  font-size: 14px;
  padding: .3em .4em .4em;
  margin: 0 .1em;
}
.tag a {
  color: #bbb;
  cursor: pointer;
  opacity: 0.6;
}
.tag a:hover {
  opacity: 1.0
}
.tag .remove {
  vertical-align: bottom;
  top: 0;
}
.tag a {
  margin: 0 0 0 .3em;
}
.tag a .glyphicon-white {
  color: #fff;
  margin-bottom: 2px;
}


.btn-gray{
    background-color: lightgray;
    color: white;
}
.btn-gray:hover{
    background-color: #615f5f;
    color: white;
}

.bg-disabled{
  background-color:#e8e8e8;
  background: #e8e8e8
}
.text-green{
  color: #3c763d;
}
.p-l-none{
  padding-left:0
}
.p-r-none{
  padding-right:0
}


