// box experts

.product-box{
  height: 500px;
  & img{
    width: 100%;

  }
}


.widget-custom-pt{
  box-shadow: #c2c2c2 0px 3px 8px 1px;
  background-color:white;
  color: #3d3939 !important;
  height: 280px;
}
.expert-img {
  width: 150px;
  height: 150px;
  background-size: cover;
  display: block;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  margin: 0 auto;
}
@media (max-width: 768px){
  .widget-custom-pt {
    height: auto;
  }
  .expert-img{
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 400px){
  .vertical-timeline-content{
    margin-left: 0;
    & h2{
      font-size: 20px;
    }
  }
  .widget-custom-pt{
    text-align: center;
  & img {
      margin: 0 auto;
    }
  }
}

@media (max-width: 992px) {
  .product-box{
    height: auto;
  }
}

.star-rating{
  font-size: 1.5em;
  display: block;
  text-align: center;
  color:#ffc000;
  margin-top:5%
}

.box-summary {
  .price{
    text-align: right;
    font-size: 16px;
    margin-top: 4%;
  }
  hr{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}