/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.4
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "variables";
@import "mixins";

// INSPINIA Theme Elements
@import "typography";
@import "navigation";
@import "top_navigation";
@import "buttons";
@import "badgets_labels";
@import "elements";
@import "sidebar";
@import "base";
@import "pages";
//@import "chat";
@import "metismenu";
@import "spinners";

@import "experts-box";

// RTL Support
@import "rtl";

// For demo only - config box style
@import "theme-config";

// INSPINIA Skins
@import "skins";
@import "md-skin";

// Media query style
@import "media";

// Media helpers
@import "helper";

// Custom style
// Your custom style to override base style
@import "custom";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  #page-wrapper {
    margin: 0;
  }
}

.diseble-box{
  background-color:#e5e6e7 ;
}

.label-optional{
  font-size: 10px;
  color: #656565;
  font-weight: normal;
}

.ui-select-multiple.ui-select-bootstrap{
  padding: 7px 3px 0 3px !important;
}

.badge-prepared { background-color: #5758BB;color: #FFFFFF}
.badge-preparing { background-color: #9980FA;color: #FFFFFF}
.label-green, .badge-green { background-color: #20bf6b; color: #FFFFFF;}