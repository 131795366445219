.p-r-none {
  padding-right: 0 ;
}
.p-l-none {
  padding-left: 0 ;
}
.p-b-none {
  padding-bottom: 0 !important;
}
.p-t-none {
  padding-top: 0 ;
}

.ui-select-match-text{
  color:#6e7173 !important;
}

.wrong-table{
  max-width: 100% !important;
  overflow-x: auto !important;
}
.wrong-table input{
  width: auto !important;
}
table.table-vertical-text tbody > tr > td{
  vertical-align: middle !important;
}
.btn-danger.btn-outline.focus.active{
  background-color: #ED5565 !important;
  color:white !important;
}
.btn-danger.btn-outline.focus{
  background-color:transparent !important;
  color:#ED5565 !important;
}
.btn-warning.btn-outline.focus.active{
  background-color: #f8ac59 !important;
  color:white !important;
}
.btn-warning.btn-outline.focus{
  background-color:transparent !important;
  color:#f8ac59 !important;
}

.navy-bg, .bg-success {
  background-color: $navy;
  color: #ffffff;
}